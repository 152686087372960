import {DesignInterface, LogoInterface} from "@stoppegp/ppicng-common";
import { Stage, Layer } from "react-konva";
import Konva from "konva";
import { ImagePreviewInterface } from "@stoppegp/ppicng-common"

const ImagePreview: React.FC<ImagePreviewInterface> = (props) => {

  return (
    <Stage
      width={props.width}
      height={props.height}
      ref={props.stageRef}
      onTouchStart={(e) => {
        if (props?.stageRef?.current) {
          props?.stageRef?.current
            .find("Transformer, .divider")
            .forEach((tf) => {
              tf.show();
            });
        }
      }}
      onMouseEnter={(e) => {
        if (props?.stageRef?.current) {
          props?.stageRef?.current
            .find("Transformer, .divider")
            .forEach((tf) => {
              tf.show();
            });
        }
      }}
      onMouseLeave={(e) => {
        if (props?.stageRef?.current) {
          props?.stageRef?.current
            .find("Transformer, .divider")
            .forEach((tf) => {
              tf.hide();
            });
        }
      }}
    >
      <Layer>
        <props.design.Image width={props.width} height={props.height} formData={props.formData} logo={props.logo} />
      </Layer>
    </Stage>
  );
};

export default ImagePreview;
