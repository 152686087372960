import { Factory } from '../Factory.js';
import { Shape } from '../Shape.js';
import { Konva } from '../Global.js';
import { getNumberValidator, getBooleanValidator } from '../Validators.js';
import { _registerNode } from '../Global.js';
export class Arc extends Shape {
    _sceneFunc(context) {
        var angle = Konva.getAngle(this.angle()), clockwise = this.clockwise();
        context.beginPath();
        context.arc(0, 0, this.outerRadius(), 0, angle, clockwise);
        context.arc(0, 0, this.innerRadius(), angle, 0, !clockwise);
        context.closePath();
        context.fillStrokeShape(this);
    }
    getWidth() {
        return this.outerRadius() * 2;
    }
    getHeight() {
        return this.outerRadius() * 2;
    }
    setWidth(width) {
        this.outerRadius(width / 2);
    }
    setHeight(height) {
        this.outerRadius(height / 2);
    }
    getSelfRect() {
        const radius = this.outerRadius();
        const DEG_TO_RAD = Math.PI / 180;
        const angle = this.angle() * DEG_TO_RAD;
        const inc = 1 * DEG_TO_RAD;
        let start = 0;
        let end = angle + inc;
        if (this.clockwise()) {
            start = end;
            end = 360;
        }
        const xs = [];
        const ys = [];
        for (let i = 0; i < end; i += inc) {
            xs.push(Math.cos(i));
            ys.push(Math.sin(i));
        }
        const minX = Math.round(radius * Math.min(...xs));
        const maxX = Math.round(radius * Math.max(...xs));
        const minY = Math.round(radius * Math.min(...ys));
        const maxY = Math.round(radius * Math.max(...ys));
        return {
            x: minX || 0,
            y: minY || 0,
            width: maxX - minX,
            height: maxY - minY
        };
    }
}
Arc.prototype._centroid = true;
Arc.prototype.className = 'Arc';
Arc.prototype._attrsAffectingSize = ['innerRadius', 'outerRadius'];
_registerNode(Arc);
Factory.addGetterSetter(Arc, 'innerRadius', 0, getNumberValidator());
Factory.addGetterSetter(Arc, 'outerRadius', 0, getNumberValidator());
Factory.addGetterSetter(Arc, 'angle', 0, getNumberValidator());
Factory.addGetterSetter(Arc, 'clockwise', false, getBooleanValidator());
